<template>
  <div class="pl-8 pr-8">
    <!-- Desktop -->
    <div class="forms-index__banner-desktop ml-3 mr-3">
      <v-row>
        <v-col class="mt-14 pl-0 pr-0 text-left " cols="12">
          <h1 class="forms__title">Reglamentos y Políticas</h1>
        </v-col>
      </v-row>
    </div>
    <v-col cols="12" class="index-form__component-desktop">
      <v-row class="pt-3">
        <v-col class="pl-0" cols="12">
          <Documents class="v-step-mobile-22"></Documents>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import Documents from "./DocumentsT&C";
export default {
  components: {
    Documents,
  },
  data() {
    return {
      name: "",
      infoToken: {
        document: "",
      },
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));

        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
  },
  created() {
    this.getToken();
  },
};
</script>

<style scoped>
@import "./../../assets/css/main.less";

.navidad-text{
  display: flex;
}
.forms-index__banner-desktop {
  display: block;
}
.desktop-hidden {
  display: none;
}

.mb-sm-index-forms {
  display: none;
}
.forms-index__banner-mobile {
  display: none;
}

.forms-spacing {
  display: none;
}

.forms__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.index-form__component-mobile {
  display: none;
}

.index-form__component-desktop {
  display: block;
}

/* LAPTOP 4k */
@media (max-width: 1900px) {
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
  /* .forms-spacing {
    padding-left: 32px !important;
    padding-right: 32px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  } */
}

/* TABLET */
@media (max-width: 1023px) {
  .index-form__component-desktop {
    display: none;
  }

  .index-form__component-mobile {
    display: block;
  }
  .forms-index__banner-desktop {
    display: none !important;
  }
  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
  .forms-index__banner-mobile {
    display: block;
  }

  .forms-spacing {
    display: block;
    /* padding-left: 12px !important;
    padding-right: 12px !important; */
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

/* MOBILE L - 767px*/
@media (max-width: 600px) {
  .forms-index__banner-desktop {
    display: none !important;
  }

  .mb-sm-index-forms {
    padding-top: 80px;
    padding-bottom: 80px;
    display: block;
  }

  .text-title-xl {
    color: #466be3 !important;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
  .forms-index__banner-mobile {
    display: block;
  }
}
/* MOBILE M */
@media (max-width: 375px) {
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
